import * as React from "react"

const IndexPage = () => {
  return (
    <main style={{background: 'url(./placeholder.png) no-repeat center center fixed', backgroundSize: 'cover', minHeight: '100vh', minWidth: '100vw', position: 'absolute', top: '0', left: '0'}}>
      <title>Work in progress | Louis Jarasius</title>
    </main>
  )
}

export default IndexPage
